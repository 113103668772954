/* You can add global styles to this file, and also import other style files */
@use "ag-grid-enterprise/styles" as ag;

$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
@use '@fortawesome/fontawesome-free/scss/fontawesome.scss';
@use '@fortawesome/fontawesome-free/scss/regular.scss';
@use '@fortawesome/fontawesome-free/scss/solid.scss';

@use 'iv-viewer/dist/iv-viewer.css';

@use 'primeicons/primeicons.css';

@use './flex.scss';
@use './fonts.scss';
@use './primeng.scss';

@use "ag-grid-enterprise/styles/ag-grid.css";
@use "ag-grid-enterprise/styles/ag-theme-balham.css";
@use "ag-grid-enterprise/styles/ag-theme-material.css";

@import 'quill/dist/quill.core.css';
@import 'quill/dist/quill.bubble.css';
@import 'quill/dist/quill.snow.css';




@media screen and (max-width: 824px) {
  html {
    font-size: 12px;
  }
}

@media screen and (min-width: 825px) and (max-width: 884px) {
  html {
    font-size: 13px;
  }
}

@media screen and (min-width: 885px) and (max-width: 949px) {
  html {
    font-size: 14px;
  }
}

@media screen and (min-width: 950px) and (max-width: 1023px) {
  html {
    font-size: 15px;
  }
}

@media screen and (min-width: 1024px) {
  html {
    font-size: 16px;
  }
}

@keyframes blink {
  50% {
    box-shadow: 0px 0px 6px #006838;
  }
}

html {
  overflow: hidden;
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.blink,
.card.blink .p-card {
  animation: blink .4s step-end infinite alternate;
}

svg > g > g.google-visualization-tooltip { pointer-events: none }

body {
  margin: 0px;
  font-family: 'Open Sans', Roboto, 'Helvetica Neue', sans-serif;
  background-color: #F0F1ED;

  em {
    background-color: yellow;
    font-style: normal !important;
  }

  a {
    color: #3496A1;
    cursor: pointer;
    text-decoration: none;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &:focus,
    &:focus-visible {
      box-shadow: none;
      outline: 0 none;
    }

    &:hover {
      color: #87BFC5;
    }
  }

  .height-100 {
    height: 100%;
  }

  .width-100 {
    width: 100%;
  }

  .hidden {
    display: none;
  }

  .cursor-pointer,
  .link {
    cursor: pointer;
  }

  .ta-center {
    text-align: center;
  }

  .bold {
    font-weight: bold;
  }

  .form {
    display: flex;
    flex-direction: column;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
  }

  .icon-external-link {
    font-size: 0.9em;
  }

  .label,
  .p-checkbox-label {
    color: #959494;
    display: block;
    // margin-bottom: .5rem;
    font-size: 0.8125rem;
    line-height: 1.125rem;
  }

  .p-button {
    &.button-bigger {
      height: 50px;
      min-width: 50px;
    }
  }

  .full-screen {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2001;
    background-color: #F0F1ED;
    height: 100% !important;

    &.card {
      .p-card {
        .p-card-header {
          padding: 1rem 1rem 0 1rem;
        }

        .p-card-body {
          padding: 1rem;
        }
      }
    }
  }

  .navigation-links {
    height: 32px;

    .link {
      font-family: Roboto, "Helvetica Neue", sans-serif;
      font-size: 0.8125rem;
      letter-spacing: 0.04em;
      line-height: 1;
      text-transform: uppercase;
      font-weight: 500;
      text-align: right;
    }

    @media screen and (max-width: 1400px) {
      .link {
        font-size: 0.75rem;
        letter-spacing: 0.01em;
      }
    }
  }

  .select-overlay-compact {
    .p-select-overlay {
      line-height: normal;
      .p-select-list-container {
        max-height: 714px !important;
        .p-select-option {
          border-bottom: 0;
          font-size: 0.8125rem;
          line-height: 1.125rem;

          &.p-disabled * {
            pointer-events: all;
          }
        }
      }
    }
  }

  .sub-header {
    color: #46535A;
    line-height: normal;
    font-size: .8rem;
    margin-top: 4px;
    overflow: hidden;
    white-space: normal;

    > ul {
      margin: .5rem 0;
      padding-inline-start: 20px;
    }
  }

  .p-dialog {
    .sub-header {
      margin-bottom: 8px;
    }

    .confirm-header-bar {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #F0F1ED;
      padding: 0 1rem;

      .icon {
        color: #46535A;
        padding-right: 1rem;
      }

      .label {
        font-size: 1rem;
        line-height: 1.4375;
        color: #46535A;
      }

      .button-cancel {
        padding-right: 1rem;
      }
    }
  }

  .card {
    @media screen and (max-height: 750px) {
      &.main {
        display: block;
        min-height: calc(100vh - 5rem - 2.6rem - 0.625rem);
      }
    }

    &.modal {
      .p-card {
        z-index: 2003;
      }
    }

    .p-card {
      position: relative;
      height: 100%;
      margin: 0;
      border-radius: 0;
      display: flex;
      flex-direction: column;

      .p-card-header {
        padding: 2rem 2rem 0 2rem;

        font-size: 1.625rem;
        font-weight: 300;
        line-height: 2.4375rem;
        white-space: nowrap;
        text-overflow: ellipsis;

        color: #46535A;
      }

      .p-card-body {
        flex: 1;
        padding: .75rem 2rem 2rem 2.5rem;

        &:only-child { // this means there's no .p-card-header
          padding: 2rem;
        }
      }

      .p-card-footer {
        display: flex;
        flex-direction: row-reverse;
        button {
          margin: 0 0.5em 0 0;
          width: auto;
        }
      }
    }

  }

  .select-overlay,
  .p-multiselect-overlay {
    width: auto !important;
    max-width: 40rem;

    .p-select-list-container,
    .p-multiselect-list-container {
      min-width: 18rem;

      .p-select-option,
      .p-multiselect-option {
        border-bottom: 1px solid gainsboro;

        .select-icon {
          margin-right: .5rem;
        }
      }
    }

    .description {
      font-size: 11px;
      line-height: normal;
      white-space: normal;
    }
  }

  .p-contextmenu {
    width: auto !important;
  }

  .iv-fullscreen {
    z-index: 2001;

    .iv-fullscreen-close {
      background-color: rgba(0, 0, 0, .5);
      border-radius: 19px;
      width: 32px;
      height: 32px;
    }
  }

  .iv-snap-view {
    top: auto;
    left: auto;
    bottom: 30px;
    right: 20px;
    opacity: 1 !important;
    pointer-events: inherit !important;
    background: rgba(50, 50, 50, 0.4);
  }

  &.leaflet--printing {
    > * {
      display: inherit !important;
    }

    .leaflet-print-overlay {
      top: -100vh;
      left: -100vw;
    }
  }

  .add-grand-child-menu {
    z-index: 2001 !important;

    .p-menu-item-link {
      &:hover {
        background-color: #F0FAFF;
      }

      .p-menu-item-icon,
      .p-menu-item-label {
        color: #3496A1 !important;
      }
    }

  }

  .diagram-toggle-dropdown {
    left: 127px !important;
  }

  .diagram-context-tiered-menu {
    min-width: 230px;
    width: auto;
    z-index: 2001 !important;

    .p-menu-item-link {
      display: flex;

      .p-menu-item-label {
        display: block;
        flex: 1;
      }

      .p-menu-item-badge {
        background: transparent;
        color: #959494;
        display: block;
        height: 0.75rem;
        line-height: 0.75rem;
        padding-right: 0;
        font-family: system-ui;
      }
    }

    &.force-left-side {
      .p-tieredmenu-submenu {
        right: 100%;
        left: unset !important;
      }
    }
  }

  .diagram-context-menu-popover {
    z-index: 2001 !important;

    &:after, &:before {
      content: unset
    }

    .p-popover-content {
      padding: 0;

      .item {
        padding: .7rem;

        &:hover {
          background-color: #F0FAFF;
        }
      }

      .separator {
        border-bottom: 1px solid gainsboro;
        padding: 0 !important;
      }
    }

  }

  .gutter.gutter-horizontal {
    border-left: 1px dashed gainsboro;
    border-right: 1px dashed gainsboro;
    cursor: col-resize;
  }

  .multiselect-toggle-all-but-no-filter {
    .p-multiselect-header {
      .p-checkbox {
        .p-checkbox-box {
          justify-content: inherit;
          .p-checkbox-icon {
            min-width: 14px;
          }
          &::after {
            content: 'Select / Deselect all';
            color: #333333;
            margin-left: 28px;
            white-space: nowrap;

          }
        }
      }
    }
  }


  .factor-rating-circle,
  .future-status-circle,
  .irreversibility-rating-circle,
  .measurement-status-circle,
  .report-status-circle,
  .scope-rating-circle,
  .severity-rating-circle,
  .threat-rating-circle,
  .viability-status-circle {
    display: inline-block;
    min-width: 1.5rem;
    min-height: 1.5rem;
    margin: auto .5rem auto 0;
  }

  .measurement-status-circle {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 1rem;

    &.Poor {
      background-color: #CC3333;
    }
    &.Fair {
      background-color: #DDBA00;
    }
    &.Good {
      background-color: #A6D4A5;
    }
    &.Very-Good {
      background-color: #037429;
    }
  }

  .factor-rating-circle {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 1rem;

    &.Low,
    &.Not-Effective {
      background-color: #CC3333;
    }
    &.Medium,
    &.Need-More-Info {
      background-color: #DDBA00;
    }
    &.High,
    &.Effective {
      background-color: #A6D4A5;
    }
    &.Very-High,
    &.Very-Effective {
      background-color: #037429;
    }
  }

  .future-status-circle {
    background: url(/static/dist/assets/img/future-status-circle.svg) no-repeat;
    background-position-y: center;
    background-size: 1.5rem;

    -webkit-mask: url(/static/dist/assets/img/future-status-circle.svg) no-repeat;
    mask: url(/static/dist/assets/img/future-status-circle.svg) no-repeat;
    mask-size: contain;

    transform: rotate(90deg);

    &.Poor {
      background: none;
      background-color: #CC3333;
    }
    &.Fair {
      background: none;
      background-color: #DDBA00;
    }
    &.Good {
      background: none;
      background-color: #A6D4A5;
    }
    &.Very-Good {
      background: none;
      background-color: #037429;
    }
  }

  .report-status-circle {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 1rem;

    &.Major-Issues,
    &.Not-Achieved {
      background-color: #CC3333;
    }
    &.Minor-Issues,
    &.Partially-Achieved {
      background-color: #DDBA00;
    }
    &.On-Track {
      background-color: #A6D4A5;
    }
    &.Planned,
    &.Not-Yet {
      background-color: #4C8699;
    }
    &.Completed,
    &.Achieved {
      background-color: #037429;
    }
    &.Abandoned,
    &.No-Longer-Relevant {
      background-color: #959393;
    }
    &.Not-Known {
      background-color: #959393;
    }
  }

  .irreversibility-rating-circle,
  .scope-rating-circle,
  .severity-rating-circle,
  .threat-rating-circle {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 1rem;

    &.Low {
      background-color: #87C089;
    }
    &.Medium {
      background-color: #D0EDD0;
    }
    &.High {
      background-color: #F3E37B;
    }
    &.Very-High {
      background-color: #D28480;
    }
  }

  .viability-status-circle {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 1rem;

    &.Poor {
      background-color: #CC3333;
    }
    &.Fair {
      background-color: #DDBA00;
    }
    &.Good {
      background-color: #A6D4A5;
    }
    &.Very-Good {
      background-color: #037429;
    }
  }

  .factor-type-icon {
    display: block;
    height: 20px;
    min-width: 20px;

    &.Activity {
      background: url(/static/dist/assets/img/activity.svg) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Activity-Output {
      background: url(/static/dist/assets/img/activity-output.png) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Assumption {
      background: url(/static/dist/assets/img/assumption.svg) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Biophysical-Factor {
      background: url(/static/dist/assets/img/biophysical-factor.svg) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Biophysical-Result {
      background: url(/static/dist/assets/img/biophysical-result.svg) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Contributing-Factor {
      background: url(/static/dist/assets/img/contributing-factor.svg) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Conceptual-Model {
      background: url(/static/dist/assets/img/conceptual-model.png) no-repeat;
      background-position: center;
    }
    &.Direct-Threat {
      background: url(/static/dist/assets/img/direct-threat.svg) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Expense-Assignment {
      background: url(/static/dist/assets/img/expense-assignment.png) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Goal {
      background: url(/static/dist/assets/img/goal.svg) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Group-Box {
      background: url(/static/dist/assets/img/group-box.svg) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Human-Wellbeing-Target,
    &.HumanWellbeing {
      background: url(/static/dist/assets/img/human-wellbeing-target.svg) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Indicator,
    &.Indicator-Simple {
      background: url(/static/dist/assets/img/indicator.svg) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Indicator-KEA {
      background: url(/static/dist/assets/img/indicator-kea.png) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Intermediate-Result {
      background: url(/static/dist/assets/img/intermediate-result.svg) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Key-Ecological-Attribute {
      background: url(/static/dist/assets/img/key-ecological-attribute.png) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Link {
      background: url(/static/dist/assets/img/link.svg) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Method {
      background: url(/static/dist/assets/img/method.svg) no-repeat;
      background-position: center;
      background-size: 19px;
    }
    &.Monitoring-Activity {
      background: url(/static/dist/assets/img/monitoring-activity.svg) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Nested-Target {
      background: url(/static/dist/assets/img/nested-target.svg) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Objective {
      background: url(/static/dist/assets/img/objective.svg) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Output {
      background: url(/static/dist/assets/img/output.svg) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Project {
      background: url(/static/dist/assets/img/project.png) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Project-Resource {
      background: url(/static/dist/assets/img/project-resource.png) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Resource-Assignment {
      background: url(/static/dist/assets/img/resource-assignment.png) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Results-Chain {
      background: url(/static/dist/assets/img/results-chain.png) no-repeat;
      background-position: center;
    }
    &.Scope-Box {
      background: url(/static/dist/assets/img/scope-box.svg) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Strategy {
      background: url(/static/dist/assets/img/strategy.svg) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Stress {
      background: url(/static/dist/assets/img/stress.svg) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Subassumption {
      background: url(/static/dist/assets/img/sub-assumption.svg) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Target,
    &.Biodiversity {
      background: url(/static/dist/assets/img/target.svg) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Task {
      background: url(/static/dist/assets/img/task.png) no-repeat;
      background-position: center;
    }
    &.Text-Box {
      background: url(/static/dist/assets/img/text-box.svg) no-repeat;
      background-position: center;
      background-size: 20px;
    }
    &.Threat-Reduction-Result {
      background: url(/static/dist/assets/img/threat-reduction-result.svg) no-repeat;
      background-position: center;
      background-size: 20px;
    }
  }

  .factor-type-name {
    color: #46535A;
    margin: 0 0 1rem 6px;
    font-weight: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .factor-type-name-v2 {
    color: #46535A;
    margin: 0;
    font-weight: normal;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .measurement-trend {
    display: inline-block;
    min-width: 1.5rem;
    min-height: 1.5rem;
    margin: auto .5rem auto 0;

    &.Unknown {
      background: url(/static/dist/assets/img/trend-unknown.png) no-repeat;
      background-position-y: center;
      background-size: 20px;
    }
    &.Strong-Increase {
      background: url(/static/dist/assets/img/trend-strong-increase.png) no-repeat;
      background-position-y: center;
      background-size: 20px;
    }
    &.Mild-Increase {
      background: url(/static/dist/assets/img/trend-mild-increase.png) no-repeat;
      background-position-y: center;
      background-size: 20px;
    }
    &.Flat {
      background: url(/static/dist/assets/img/trend-flat.png) no-repeat;
      background-position-y: center;
      background-size: 20px;
    }
    &.Mild-Decrease {
      background: url(/static/dist/assets/img/trend-mild-decrease.png) no-repeat;
      background-position-y: center;
      background-size: 20px;
    }
    &.Strong-Decrease {
      background: url(/static/dist/assets/img/trend-strong-decrease.png) no-repeat;
      background-position-y: center;
      background-size: 20px;
    }
  }

}
