.flex-full-screen {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: 440px;
  height: 100vh;
}

.flex-1 {
  flex: 1;
  min-width: 0;
  min-height: 0;
}

.flex-col {
  display: flex;
  flex-direction: column;

  &.center { // horizontally
    align-items: center;
  }

  &.end {
    align-items: end;
  }

  &.gap-1 {
    column-gap: 0.5rem;
    row-gap: 0.5rem;
  }

  &.gap-2 {
    column-gap: 1rem;
    row-gap: 1rem;
  }

  &.middle { // vertically
    align-self: center;
    justify-content: center;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.start {
    align-items: start;
  }

  &.stretch {
    width: 100%;
  }

  &.wrap {
    flex-wrap: wrap;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;

  &.center {
    justify-content: center;
  }

  &.end {
    align-items: end;
  }

  &.gap-1 {
    column-gap: 0.5rem;
    row-gap: 0.5rem;
  }

  &.gap-2 {
    column-gap: 1rem;
    row-gap: 1rem;
  }

  &.middle {
    align-items: center;
  }

  &.nowrap {
    flex-wrap: nowrap;
  }

  &.reverse {
    flex-direction: row-reverse;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.space-evenly {
    justify-content: space-evenly;
  }

  &.stretch {
    height: 100%;
    align-items: stretch;
  }

  &.wrap {
    flex-wrap: wrap;
  }
}

@media all and (-ms-high-contrast: none) { /* IE11 Only */
  .flex-row {
    &.space-evenly {
      justify-content: space-around;
    }
  }
}

@supports (-ms-ime-align: auto) { /* Edge Only */
  .flex-row {
    &.space-evenly {
      justify-content: space-around;
    }
  }
}
