@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('/static/dist/assets/fonts/OpenSans/Light/OpenSans-Light.eot'); /* IE9 Compat Modes */
  src: url('/static/dist/assets/fonts/OpenSans/Light/OpenSans-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/static/dist/assets/fonts/OpenSans/Light/OpenSans-Light.woff') format('woff'), /* Modern Browsers */
       url('/static/dist/assets/fonts/OpenSans/Light/OpenSans-Light.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/static/dist/assets/fonts/OpenSans/Light/OpenSans-Light.svg#svgFontName') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url('/static/dist/assets/fonts/OpenSans/LightItalic/OpenSans-LightItalic.eot'); /* IE9 Compat Modes */
  src: url('/static/dist/assets/fonts/OpenSans/LightItalic/OpenSans-LightItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/static/dist/assets/fonts/OpenSans/LightItalic/OpenSans-LightItalic.woff') format('woff'), /* Modern Browsers */
       url('/static/dist/assets/fonts/OpenSans/LightItalic/OpenSans-LightItalic.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/static/dist/assets/fonts/OpenSans/LightItalic/OpenSans-LightItalic.svg#svgFontName') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/static/dist/assets/fonts/OpenSans/Regular/OpenSans-Regular.eot'); /* IE9 Compat Modes */
  src: url('/static/dist/assets/fonts/OpenSans/Regular/OpenSans-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/static/dist/assets/fonts/OpenSans/Regular/OpenSans-Regular.woff') format('woff'), /* Modern Browsers */
       url('/static/dist/assets/fonts/OpenSans/Regular/OpenSans-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/static/dist/assets/fonts/OpenSans/Regular/OpenSans-Regular.svg#svgFontName') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('/static/dist/assets/fonts/OpenSans/Italic/OpenSans-Italic.eot'); /* IE9 Compat Modes */
  src: url('/static/dist/assets/fonts/OpenSans/Italic/OpenSans-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/static/dist/assets/fonts/OpenSans/Italic/OpenSans-Italic.woff') format('woff'), /* Modern Browsers */
       url('/static/dist/assets/fonts/OpenSans/Italic/OpenSans-Italic.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/static/dist/assets/fonts/OpenSans/Italic/OpenSans-Italic.svg#svgFontName') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('/static/dist/assets/fonts/OpenSans/SemiBold/OpenSans-SemiBold.eot'); /* IE9 Compat Modes */
  src: url('/static/dist/assets/fonts/OpenSans/SemiBold/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/static/dist/assets/fonts/OpenSans/SemiBold/OpenSans-SemiBold.woff') format('woff'), /* Modern Browsers */
       url('/static/dist/assets/fonts/OpenSans/SemiBold/OpenSans-SemiBold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/static/dist/assets/fonts/OpenSans/SemiBold/OpenSans-SemiBold.svg#svgFontName') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url('/static/dist/assets/fonts/OpenSans/SemiBoldItalic/OpenSans-SemiBoldItalic.eot'); /* IE9 Compat Modes */
  src: url('/static/dist/assets/fonts/OpenSans/SemiBoldItalic/OpenSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/static/dist/assets/fonts/OpenSans/SemiBoldItalic/OpenSans-SemiBoldItalic.woff') format('woff'), /* Modern Browsers */
       url('/static/dist/assets/fonts/OpenSans/SemiBoldItalic/OpenSans-SemiBoldItalic.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/static/dist/assets/fonts/OpenSans/SemiBoldItalic/OpenSans-SemiBoldItalic.svg#svgFontName') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('/static/dist/assets/fonts/OpenSans/Bold/OpenSans-Bold.eot'); /* IE9 Compat Modes */
  src: url('/static/dist/assets/fonts/OpenSans/Bold/OpenSans-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/static/dist/assets/fonts/OpenSans/Bold/OpenSans-Bold.woff') format('woff'), /* Modern Browsers */
       url('/static/dist/assets/fonts/OpenSans/Bold/OpenSans-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('/static/dist/assets/fonts/OpenSans/Bold/OpenSans-Bold.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  src: url('/static/dist/assets/fonts/Roboto/Roboto-Thin.ttf')  format('truetype'), /* Safari, Android, iOS */
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 100;
  src: url('/static/dist/assets/fonts/Roboto/Roboto-ThinItalic.ttf')  format('truetype'), /* Safari, Android, iOS */
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  src: url('/static/dist/assets/fonts/Roboto/Roboto-Light.ttf')  format('truetype'), /* Safari, Android, iOS */
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 300;
  src: url('/static/dist/assets/fonts/Roboto/Roboto-LightItalic.ttf')  format('truetype'), /* Safari, Android, iOS */
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  src: url('/static/dist/assets/fonts/Roboto/Roboto-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 400;
  src: url('/static/dist/assets/fonts/Roboto/Roboto-Italic.ttf')  format('truetype'), /* Safari, Android, iOS */
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  src: url('/static/dist/assets/fonts/Roboto/Roboto-Medium.ttf')  format('truetype'), /* Safari, Android, iOS */
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 500;
  src: url('/static/dist/assets/fonts/Roboto/Roboto-MediumItalic.ttf')  format('truetype'), /* Safari, Android, iOS */
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  src: url('/static/dist/assets/fonts/Roboto/Roboto-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 700;
  src: url('/static/dist/assets/fonts/Roboto/Roboto-BoldItalic.ttf')  format('truetype'), /* Safari, Android, iOS */
}
@font-face {
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  src: url('/static/dist/assets/fonts/Roboto/Roboto-Black.ttf')  format('truetype'), /* Safari, Android, iOS */
}
@font-face {
  font-family: Roboto;
  font-style: italic;
  font-weight: 900;
  src: url('/static/dist/assets/fonts/Roboto/Roboto-BlackItalic.ttf')  format('truetype'), /* Safari, Android, iOS */
}